.mainfooter_container {
  margin-top: 2rem;
  background-color: #3a3a3a;
  color: white;
  font-family: "Poppins", sans-serif;
}

.footer_container {
  padding: 3rem 2rem;
}

@media only screen and (min-width: 1000px) {
  .footer_container {
    max-width: 1300px;
    margin: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover{
  color: #f6832a;
}

.mulearn {
  width: 6rem;
}

.links {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
}

.link {
  margin-top: 0;
  margin-right: 1rem;
  font-weight: 300;
  font-size: 0.9rem;
}

.link:hover {
  color: #f1954a;
}

.line {
  margin-top: 2rem;
  background-color: rgb(97, 97, 97);
  width: 100%;
  height: 2px;
}

a {
  font-weight: 500;
}

.address,
.mail {
  margin-top: 2rem;
  font-size: 0.8rem;
  font-weight: 300;
  width: 75%;
}

.mail {
  color: #f1954a;
}

.social {
  width: 2rem;
  margin-top: 2rem;
  margin-right: 1rem;
}

@media only screen and (min-width: 1000px) {
  .first_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .address_container {
    font-size: 0.9rem;
    width: 35%;
  }

  .address,
  .mail {
    margin-top: 2rem;
    font-size: 0.9rem;
    font-weight: 300;
    width: 75%;
  }
}

.mail {
  font-weight: 600;
}

.mailus {
  display: block;
  padding-top: 1rem;
  color: rgb(176, 176, 245);
}

.title {
  font-size: 2rem;
  font-weight: 700;
  color: #5e72e4;
}

.fheadingtext {
  font-size: 1.1rem;
  color: white;
  margin-top: 4vh;
}

.fparagraph {
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
}

.newsletterinvite {
  display: flex;
  flex-direction: row;
}

.invite_container {
  position: relative;
  background-color: white;
  padding: 0.5rem 1rem;
  margin-top: 2vh;
  border-radius: 5px;
}

.invite_container input {
  font-size: 0.8rem;
  border: none;
  padding: 0.5rem;
  width: 12.5rem;
}

.invite_container button {
  border: none;
  font-size: 0.8rem;
  color: white;
  background-color: #f6832a;
  padding: 0.5rem 1.75rem;
  border-radius: 3px;
}

.invite_container button:hover {
  background-color: #fd7915;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.newsletter {
  width: 20rem;
}

.newslettertext {
  color: white;
  display: flex;
  flex-direction: row;
}

.fs_rightside {
  display: flex;
}

@media only screen and (min-width: 1000px) {
  .right_side {
    width: 30%;
  }

  .newsletter {
    width: 20rem;
    text-align: right;
  }

  .bottom_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
