* {
  padding: 0;
  margin: 0;
}

nav {
  margin: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (min-width: 1024px) {
  nav {
    max-width: 1300px;
    margin: auto;
    margin-top: 2rem;
  }
}

.joindiscord {
  background-color: #f68128;
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  border: none;
}

.mulearn_img {
  width: 6rem;
  object-fit: contain;
}

/* First View Styles */

.first_view {
  margin: 2rem;
  margin-top: 4rem;
}

.fv_texts {
  text-align: center;
}

.quote {
  margin-top: 0.15rem;
  font-size: 1.25rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

.start_contributing {
  background-color: #f68128;
  color: white;
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.fv_heading {
  font-family: "Raleway", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
}

.event_partners_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.1rem;
  margin-top: 2.5rem;
  font-weight: 500;
  margin-right: 1rem;
}

.partners {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin: 1rem 0;
}

.partner_image {
  width: 5rem;
  margin-right: 0.5rem;
}

span {
  color: #f68128;
}

.fv_tagline {
  font-family: "Poppins", sans-serif;
}

.fv_image {
  object-fit: cover;
  object-position: 45%;
  height: 20rem;
  width: 100vw;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    margin-top: 4rem;
  }

  .quote {
    font-size: 1.75rem;
    font-weight: 400;
  }

  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3rem;
  }

  .fv_image {
    object-fit: cover;
    object-position: center;
    height: fit-content;
    height: 30rem;
    width: 99vw;
    margin: 3rem auto;
  }

  .fv_heading {
    font-family: "Raleway", sans-serif;
    font-size: 4rem;
    font-weight: 600;
  }

  .fv_tagline {
    font-family: "Poppins", sans-serif;
    font-size: 1.1rem;
    margin-top: 0.5rem;
    width: 70%;
    margin: auto;
  }
}

/* Second Section Styles */
.second_view {
  margin: 2rem;
  margin-top: 4rem;
}

.sv_heading {
  font-family: "Raleway", sans-serif;
  font-size: 2.45rem;
  color: #1f172e;
  font-weight: 600;
}

.sv_tagline,
.right_side {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    margin-top: 6rem;
  }

  .sv_texts {
    width: 45%;
  }

  .sv_heading {
    width: 90%;
    font-family: "Raleway", sans-serif;
    font-size: 2.75rem;
  }

  .sv_tagline {
    width: 85%;
  }

  .right_side {
    width: 45%;
  }

  .second_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
  }
}

/* Steps Styles */

/* Steps Styles */

.steps_view_container {
  margin: 2rem;
  margin-top: 4rem;
}

.steps_view {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.stv_heading {
  font-family: "Raleway", sans-serif;
  font-size: 2.45rem;
  color: #1f172e;
  font-weight: 600;
}

.stv_tagline {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.step_heading {
  color: #404040;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
}

.step_text {
  font-family: "Poppins", sans-serif;
  margin-top: 0.25rem;
}

.link {
  color: #1f172e;
  text-decoration: underline;
}

.step {
  margin: 1rem 0rem;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
}

@media only screen and (min-width: 1024px) {
  .steps_view_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 6rem;
  }

  .stv_tagline {
    width: 50%;
  }

  .steps_view {
    margin: 2rem;
    display: flex;
    flex-direction: column;
  }

  .steps_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .step {
    width: 20rem;
    margin: 1.5rem;
    margin-right: 3rem;
    margin-left: 0;
  }
}

@media only screen and (min-width: 1300px) {
  .steps_view {
    margin: 0;
  }

  .sv_point {
    width: 23rem;
  }
}

/* Third View Styles */

/* Third Section Styles */

.third_view_container {
  margin: 2rem;
}

.third_view {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.tvheader {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.45rem;
  color: #1f172e;
  font-weight: 600;
}

.tvtagline {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.tvpoints {
  margin-top: 1rem;
  margin-left: 1rem;
}

.listitem {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
  display: list-item;
}

@media only screen and (min-width: 1024px) {
  .third_view_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .listitem {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    margin-top: 0.5rem;
    display: list-item;
  }

  .third_view {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem;
  }

  .tv_texts {
    width: 60%;
  }

  .tvtagline {
    font-family: "Poppins", sans-serif;
    text-align: left;
    font-size: 1rem;
    width: 60%;
    margin-top: 0.5rem;
  }

  .tvheader {
    font-family: "Raleway", sans-serif;
    font-size: 2.25rem;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1300px) {
  .third_view {
    margin: 0;
  }
}

/* Foruth View Styles */

.fourth_view_container {
  margin: 2rem;
  margin-top: 4rem;
}

.fourth_view {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.frv_heading {
  font-family: "Raleway", sans-serif;
  font-size: 2.45rem;
  color: #1f172e;
  font-weight: 600;
}

.frv_text {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.sv_points {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.svp_heading {
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
}

.svp_text {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.sv_image {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.sv_point {
  text-align: center;
  margin: 1rem 0rem;
}

@media only screen and (min-width: 1024px) {
  .fourth_view_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 6rem;
  }

  .frv_text {
    width: 60%;
  }

  .fourth_view {
    margin: 2rem;
    display: flex;
    flex-direction: column;
  }

  .sv_points {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 3rem;
  }

  .sv_point {
    text-align: center;
    margin: 1rem;
  }

  .svp_heading {
    font-family: "Raleway", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .svp_text {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1300px) {
  .fourth_view {
    margin: 0rem;
  }
}

/* Fifth View */

.fifth_view_container {
  margin: 2rem;
  margin-top: 4rem;
}

.fifth_view {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.ffv_heading {
  font-family: "Raleway", sans-serif;
  font-size: 2.45rem;
  color: #1f172e;
  font-weight: 600;
}

.ffv_text {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  max-width: 30rem;
  margin-top: 0.5rem;
}

@media only screen and (min-width: 1024px) {
  .fifth_view_container {
    max-width: 1300px;
    margin: auto;
    margin-top: 6rem;
  }

  .ffv_text {
    width: 60%;
  }

  .fifth_view {
    margin: 2rem;
    display: flex;
    flex-direction: column;
  }

  .svp_heading {
    font-family: "Raleway", sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .svp_text {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1300px) {
  .fifth_view {
    margin: 0rem;
  }
}

.ffv_cards_container {
  margin: 2rem;
}

.storeImage {
  height: 2rem;
}

.ffv_cards {
  display: flex;
  flex-direction: row;
  row-gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
}

.ffv_card {
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
  max-width: 20rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ffvc_image {
  width: 100%;
  height: 10rem;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.ffvc_heading {
  font-family: "Raleway", sans-serif;
  font-size: 1.75rem;
  color: #1f172e;
  font-weight: 600;
  margin-top: 1rem;
}

.ffvc_description {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  margin-top: 1rem;
}

.ffvc_builtby {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.ffvc_button {
  background-color: #f68128;
  color: white;
  margin-top: 1rem;
  margin-right: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
